import { Link } from "gatsby"
import React, { ReactElement } from "react"

interface Props {
  title: string
  className: string
  to: string
  src: string
}

const WorkTile = ({ to, title, className, src }: Props): ReactElement => {
  const imgSrc = `https://res.cloudinary.com/justinpmartin/image/upload/c_scale,w_950/${src}`
  return (
    <Link to={to} className={`work-tile ${className}`}>
      <img
        src={imgSrc}
        alt={title}
        className="absolute inset-0 w-full h-full object-cover z-0"
      />
      <div className="work-tile__title">{title}</div>
    </Link>
  )
}

export default WorkTile
