import React, { ReactElement } from "react"
import Layout from "../components/layout"
import DotPattern from "../images/pattern-dots.svg"
import AboutPattern from "../images/pattern-hatches.svg"
import CapabilitiesPattern from "../images/pattern-angles.svg"
import Headshot from "../images/headshot.png"
import HeroBg from "../images/hero-bg.png"
import WorkTile from "../components/workTile"
import Footer from "../components/Footer"
import Seo from "../components/Seo"

export default function Home(): ReactElement {
  return (
    <Layout>
      <Seo title="Front-end Developer" />
      <div className="hero">
        <img
          src={HeroBg}
          alt="Designer Developer"
          className="hidden xl:block absolute top-0 left-0 h-full w-auto object-cover"
        />
        <img
          src={Headshot}
          alt="Headshot"
          className="absolute top-0 left-0 w-full opacity-30 xl:opacity-100 xl:w-1/4 h-full object-cover"
        />
        <div className="container text-grey py-20 md:pb-32 md:pt-8 3xl:pb-64 relative z-10 lg:flex lg:justify-end">
          <div className="lg:w-8/12 lg:mx-auto xl:w-1/2 xl:offset-r-1/12 xl:mr-0">
            <div className="eyebrow eyebrow--hero">Who Am I</div>
            <h1 className="text-red heading text-3xl md:text-6xl uppercase">
              An experienced Front-end Developer
            </h1>
            <p className="text mt-4 2xl:mt-8">
              With 16 years experience, I make sure things are as functional as
              they are eye catching.{" "}
            </p>
            <p className="font-utopia text-grey-300 text-lg md:text-2xl mt-8 2xl:mt-16">
              <a className="hover-link" href="mailto:justinpmartin@gmail.com">
                justinpmartin@gmail.com
              </a>{" "}
              /{" "}
              <a
                href="tel:1-403-701-4255"
                className="hover-link whitespace-nowrap"
              >
                1 (403) 701-4255
              </a>
            </p>
          </div>

          <a
            href="/justin_martin_resume.pdf"
            className="btn btn--resume"
            target="_blank"
          >
            <span className="relative flex items-bottom w-full">
              Resumé
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="31"
                overflow="visible"
                className="btn__icon h-5 xl:h-8"
                viewBox="0 0 31 31"
              >
                <path d="M27.417 30.917H3.5c-1.75 0-3.5-1.167-3.5-3.5v-5.834c0-1.75 1.75-3.5 3.5-3.5h5.25c.583 0 1.167 0 1.167.584.583 1.166 1.166 1.75 2.333 2.333 1.75 1.167 3.5 1.167 5.25.583 1.75-.583 2.917-1.75 4.083-2.916 0-.584.584-.584 1.167-.584H28c1.75 0 3.5 1.75 3.5 3.5v5.834c-.583 2.333-1.75 3.5-4.083 3.5zM3.5 21s-.583 0 0 0l-.583 6.417s0 .583.583.583h23.917S28 28 28 27.417v-5.834S28 21 27.417 21h-4.084c-1.166 1.75-2.916 2.917-5.25 3.5-2.333.583-5.25.583-7-.583-1.166-.584-2.333-1.75-3.5-2.917H3.5zM15.75 16.917c-.583 0-1.75-.584-1.75-1.75V1.75C14 .583 14.583 0 15.75 0c.583 0 1.75.583 1.75 1.75v14c-.583.583-1.167 1.167-1.75 1.167z" />
                <path d="M15.75 16.917c-.583 0-.583 0-1.167-.584l-5.25-3.5c-.583-.583-.583-1.75 0-2.333.584-.583 1.75-.583 2.334 0L15.75 14l4.083-3.5c.584-.583 1.75-.583 2.334 0 .583.583.583 1.75 0 2.333l-5.834 4.084h-.583z" />
              </svg>
            </span>
          </a>
        </div>
        <div
          className="hero-pattern"
          style={{ backgroundImage: `url(${DotPattern})` }}
        />
      </div>
      <div className="bg-grey-300 py-10 md:py-12">
        <div id="about" className="relative">
          <div className="container py-10 md:py-20">
            <div className="md:w-10/12 lg:w-7/12 md:offset-l-1/12 lg:offset-l-2/12 2xl:offset-l-1/12">
              <div className="eyebrow">Why Work With Me</div>
              <h2 className="heading text-red text-4xl md:text-6xl uppercase">
                If you Value{" "}
                <span className="font-utopiaItalic capitalize">
                  Custom Built
                </span>{" "}
                Solutions.
              </h2>
              <p className="text mt-8">
                One of my advantages is that I can bridge the gap between
                singular focused developers and designers. I understand how big
                of a difference typography makes and how consistency of branding
                fits into the larger picture. I also understand the technical
                aspects that are required to bring designs to life in the
                digital space. I value clean code as much as a clean design
                aesthetic.
              </p>
            </div>
          </div>
          <div
            className="about-pattern"
            style={{ backgroundImage: `url(${AboutPattern})` }}
          />
          <div
            className="capabilities-pattern"
            style={{ backgroundImage: `url(${CapabilitiesPattern})` }}
          />
        </div>
        <div className="relative">
          <div className="skills-img">
            <img
              src="./skills-img.png"
              className="md:absolute md:inset-0 w-full md:h-full object-cover"
              alt="Skills"
            />
            <div
              className="skills-pattern hidden lg:block"
              style={{ backgroundImage: `url(${DotPattern})` }}
            />
            <div className="hidden lg:block absolute top-0 left-0 transform -translate-x-1/2 btn btn--sm btn--skills mt-20">
              Skills
            </div>
          </div>
          <div className="container py-10 md:py-20 2xl:pb-32">
            <div className="md:w-8/12 lg:w-6/12 md:offset-l-1/12 2xl:ml-0">
              <div className="eyebrow"> My Capabilities</div>
              <ul>
                <li className="heading text-2xl md:text-4xl text-charcoal">
                  UI/UX Design
                </li>
                <li className="heading text-2xl md:text-4xl text-charcoal">
                  Hybrid Mobile Apps
                </li>
                <li className="heading text-2xl md:text-4xl text-charcoal">
                  Javascript (React, Vue)
                </li>
                <li className="heading text-2xl md:text-4xl text-charcoal">
                  PHP (Laravel, Wordpress)
                </li>
                <li className="heading text-2xl md:text-4xl text-charcoal">
                  HTML
                </li>
                <li className="heading text-2xl md:text-4xl text-charcoal">
                  CSS
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container md:flex">
          <div className="md:w-6/12 lg:w-4/12 md:offset-l-1/12 py-5 md:py-10 2xl:ml-0">
            <h3 className="heading text-2xl text-charcoal">Designer</h3>
            <p className="text">
              Creating a well thought out user experience while maintaining a
              clean design asthetic.
            </p>
          </div>
          <div className="md:w-6/12 lg:w-4/12 py-5 md:py-10 md:offset-l-1/12">
            <h3 className="heading text-2xl text-charcoal">Developer</h3>
            <p className="text">
              Using frameworks such as React to build SPAs and Hybrib mobile
              apps with reusable and responsive design systems
            </p>
          </div>
        </div>
        <div id="work" className="py-10 2xl:py-20">
          <div className="container flex justify-end">
            <div className="lg:w-4/12 lg:offset-r-1/12 2xl:mr-0">
              <div className="eyebrow">My Work</div>
              <h2 className="heading text-4xl text-charcoal uppercase leading-none mb-2">
                Projects that I have worked on
              </h2>
              <p className="text">
                I’ve worked on many different kinds of projects for clients big
                and small that span multiple kinds of industries.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:flex lg:flex-row-reverse">
          <div className="lg:w-1/2">
            <div className="p-3">
              <WorkTile
                to="/work/communo"
                className="aspect-ratio-4/3"
                title="Communo"
                src="v1608844112/portfolio/Communo/iPhone-flat-mockup_jtidpx.jpg"
              />
            </div>
            <div className="p-3 lg:w-7/12">
              <WorkTile
                to="/work/imagewear"
                className="aspect-ratio-4/3 lg:aspect-ratio-16/9"
                title="Imagewear"
                src="v1609278272/portfolio/Imagewear/work-tile-3_ny6ybh.jpg"
              />
            </div>
          </div>
          <div className="lg:w-1/2 lg:-mt-40">
            <div className="p-3 lg:w-7/12 ml-auto">
              <WorkTile
                to="/work/money-mentors"
                className="aspect-ratio-4/3"
                title="Money Mentors"
                src="v1609229925/portfolio/MoneyMentors/money-tips-iphone_fcvvug.jpg"
              />
            </div>
            <div className="lg:flex">
              <div className="lg:w-8/12 p-3">
                <WorkTile
                  to="/work/marris-miller"
                  className="aspect-ratio-4/3"
                  title="Marris Miller"
                  src="v1609221935/portfolio/MarrisMiller/work-tile_bnvpc5.jpg"
                />
                <div className="hidden lg:inline-block btn btn--work btn--sm width-auto mt-5">
                  Work
                </div>
              </div>
              <div className="lg:w-4/12 p-3">
                <WorkTile
                  to="/work/chatmail"
                  className="aspect-ratio-4/3 lg:aspect-ratio-square"
                  title="ChatMail"
                  src="v1609221208/portfolio/ChatMail/work-tile-2_ma0yde.jpg"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

// export const query = graphql`
//   query {
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       totalCount
//       edges {
//         node {
//           id
//           frontmatter {
//             title
//             date(formatString: "DD MMMM, YYYY")
//           }
//           fields {
//             slug
//           }
//           excerpt
//         }
//       }
//     }
//   }
// `
